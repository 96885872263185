import React, { useContext } from "react";
import Img from "gatsby-image";
import { CursorContext } from "~context/CursorContext";
import { DocumentContext } from "~context/DocumentContext";

const Cursor = () => {
  const cursorContext = useContext(CursorContext);
  const documentContext = useContext(DocumentContext);
  const { cursorPositionX, cursorPositionY, hovering } = cursorContext;
  const { device } = documentContext;

  return (
    <>
      {device === `desktop` && (
        <div
          className={`cursor ${
            hovering ? `hovering` : ``
          } fixed z-50 pointer-events-none`}
          style={{
            transform: `translate3d(${cursorPositionX}px, ${cursorPositionY}px, 0)`
          }}
        >
          <svg className="w-16 h-16 relative" viewBox="0 0 156 156" fill="none">
            <path
              d="M89.6722 25.3192C73.7305 39.8543 53.5689 41.9643 36.6894 27.6636L27.5463 36.8067C41.847 53.6861 39.8543 73.7305 25.3192 89.6722L38.5649 98.2292C47.9424 86.7418 52.3967 67.9868 45.1291 54.3894L118.742 128.003L127.885 118.86L54.2722 45.2464C67.8695 52.5139 86.7417 47.9424 98.1119 38.6821L89.6722 25.3192Z"
              fill="#1F1F1F"
            />
          </svg>
        </div>
      )}
    </>
  );
};

export default Cursor;
