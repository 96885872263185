import React from "react";
import PropTypes from "prop-types";

const Icon = ({ className, color }) => (
  <svg className={className} viewBox="0 0 152 65" fill="none">
    <path
      d="M75.1801 37.2897H93.0649C103.12 37.2897 111.774 31.0952 111.774 20.2757C111.774 9.45613 103.614 3.26172 93.0649 3.26172H67.5977V61.0762H75.1801V37.2897ZM75.1801 30.1041V10.4472H92.4056C98.6693 10.4472 103.779 13.5031 103.779 20.2757C103.779 27.0482 98.6693 30.1041 92.4056 30.1041H75.1801Z"
      fill={color}
    />
    <path
      d="M31.4273 64.0406C48.9649 64.0406 62.8547 50.1747 62.8547 32.0203C62.8547 14.1518 49.2455 0 31.4273 0C13.8898 0 0 13.8659 0 32.0203C0 49.7458 13.7495 64.0406 31.4273 64.0406ZM31.4273 58.6086C16.8361 58.6086 5.47173 47.0298 5.47173 32.0203C5.47173 17.1537 16.5555 5.43202 31.4273 5.43202C46.0186 5.43202 57.383 16.8678 57.383 32.0203C57.383 46.8869 46.2992 58.6086 31.4273 58.6086ZM31.7079 50.6035C40.8275 50.6035 47.2813 45.0286 49.3858 36.1658L44.0544 34.1645C42.6514 41.169 39.0036 45.1715 31.7079 45.1715C24.272 45.1715 19.5018 39.1677 19.5018 31.8774C19.5018 24.587 23.8511 18.7262 31.287 18.7262C37.0394 18.7262 41.529 22.014 42.3708 28.0178L47.4216 26.4453C46.0186 17.8685 39.5648 13.4371 31.287 13.4371C21.1854 13.4371 13.4689 21.5851 13.4689 31.8774C13.4689 42.7414 21.0451 50.6035 31.7079 50.6035Z"
      fill={color}
    />
    <path
      d="M122.172 19.5682H125.094V6.48387H130.685V3.85449H116.518V6.48387H122.172V19.5682ZM132.401 19.5682H135.323V5.85783L139.77 19.5682H143.773L148.22 5.85783V19.5682H151.206V3.85449H145.87L141.803 16.9388L137.737 3.85449H132.401V19.5682Z"
      fill={color}
    />
  </svg>
);

Icon.defaultProps = {
  className: ``,
  color: `#FDFBF8`
};

Icon.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string
};

export default Icon;
