/* eslint-disable react/prop-types */

import React, { Component, useContext } from "react";
import { clearAllBodyScrollLocks, disableBodyScroll } from "body-scroll-lock";
import Div100vh from "react-div-100vh";
import { AppContext } from "~context/AppContext";

import frame0 from "~assets/images/animation/0.png";
import frame1 from "~assets/images/animation/1.png";
import frame2 from "~assets/images/animation/2.png";
import frame3 from "~assets/images/animation/3.png";
import frame4 from "~assets/images/animation/4.png";
import frame5 from "~assets/images/animation/5.png";
import frame6 from "~assets/images/animation/6.png";
import frame7 from "~assets/images/animation/7.png";
import frame8 from "~assets/images/animation/8.png";
import frame9 from "~assets/images/animation/9.png";
import frame10 from "~assets/images/animation/10.png";
import frame11 from "~assets/images/animation/11.png";
import frame12 from "~assets/images/animation/12.png";
import frame13 from "~assets/images/animation/13.png";
import frame14 from "~assets/images/animation/14.png";
import frame15 from "~assets/images/animation/15.png";
import frame16 from "~assets/images/animation/16.png";
import frame17 from "~assets/images/animation/17.png";
import frame18 from "~assets/images/animation/18.png";
import frame19 from "~assets/images/animation/19.png";
import frame20 from "~assets/images/animation/20.png";
import frame21 from "~assets/images/animation/21.png";
import frame22 from "~assets/images/animation/22.png";
import frame23 from "~assets/images/animation/23.png";
import frame24 from "~assets/images/animation/24.png";
import frame25 from "~assets/images/animation/25.png";
import frame26 from "~assets/images/animation/26.png";
import frame27 from "~assets/images/animation/27.png";
import frame28 from "~assets/images/animation/28.png";
import frame29 from "~assets/images/animation/29.png";
import frame30 from "~assets/images/animation/30.png";
import frame31 from "~assets/images/animation/31.png";
import frame32 from "~assets/images/animation/32.png";
import frame33 from "~assets/images/animation/33.png";
import frame34 from "~assets/images/animation/34.png";
import frame35 from "~assets/images/animation/35.png";
import frame36 from "~assets/images/animation/36.png";
import frame37 from "~assets/images/animation/37.png";
import frame38 from "~assets/images/animation/38.png";
import frame39 from "~assets/images/animation/39.png";
import frame40 from "~assets/images/animation/40.png";
import frame41 from "~assets/images/animation/41.png";
import frame42 from "~assets/images/animation/42.png";
import frame43 from "~assets/images/animation/43.png";
import frame44 from "~assets/images/animation/44.png";
import frame45 from "~assets/images/animation/45.png";
import frame46 from "~assets/images/animation/46.png";
import frame47 from "~assets/images/animation/47.png";
import frame48 from "~assets/images/animation/48.png";
import frame49 from "~assets/images/animation/49.png";
import frame50 from "~assets/images/animation/50.png";
import frame51 from "~assets/images/animation/51.png";
import frame52 from "~assets/images/animation/52.png";
import frame53 from "~assets/images/animation/53.png";
import frame54 from "~assets/images/animation/54.png";
import frame55 from "~assets/images/animation/55.png";
import frame56 from "~assets/images/animation/56.png";
import frame57 from "~assets/images/animation/57.png";
import frame58 from "~assets/images/animation/58.png";
import frame59 from "~assets/images/animation/59.png";
import frame60 from "~assets/images/animation/60.png";
import frame61 from "~assets/images/animation/61.png";
import frame62 from "~assets/images/animation/62.png";
import frame63 from "~assets/images/animation/63.png";
import frame64 from "~assets/images/animation/64.png";
import frame65 from "~assets/images/animation/65.png";
import frame66 from "~assets/images/animation/66.png";
import frame67 from "~assets/images/animation/67.png";
import frame68 from "~assets/images/animation/68.png";
import frame69 from "~assets/images/animation/69.png";
import frame70 from "~assets/images/animation/70.png";
import frame71 from "~assets/images/animation/71.png";
import frame72 from "~assets/images/animation/72.png";
import frame73 from "~assets/images/animation/73.png";
import frame74 from "~assets/images/animation/74.png";
import frame75 from "~assets/images/animation/75.png";
import frame76 from "~assets/images/animation/76.png";
import frame77 from "~assets/images/animation/77.png";
import frame78 from "~assets/images/animation/78.png";
import frame79 from "~assets/images/animation/79.png";
import frame80 from "~assets/images/animation/80.png";
import frame81 from "~assets/images/animation/81.png";
import frame82 from "~assets/images/animation/82.png";
import frame83 from "~assets/images/animation/83.png";
import frame84 from "~assets/images/animation/84.png";
import frame85 from "~assets/images/animation/85.png";
import frame86 from "~assets/images/animation/86.png";
import frame87 from "~assets/images/animation/87.png";
import frame88 from "~assets/images/animation/88.png";
import frame89 from "~assets/images/animation/89.png";
import frame90 from "~assets/images/animation/90.png";
import frame91 from "~assets/images/animation/91.png";
import frame92 from "~assets/images/animation/92.png";
import frame93 from "~assets/images/animation/93.png";
import frame94 from "~assets/images/animation/94.png";
import frame95 from "~assets/images/animation/95.png";
import frame96 from "~assets/images/animation/96.png";
import frame97 from "~assets/images/animation/97.png";
import frame98 from "~assets/images/animation/98.png";
import frame99 from "~assets/images/animation/99.png";
import frame100 from "~assets/images/animation/100.png";
import frame101 from "~assets/images/animation/101.png";
import frame102 from "~assets/images/animation/102.png";
import frame103 from "~assets/images/animation/103.png";
import frame104 from "~assets/images/animation/104.png";
import frame105 from "~assets/images/animation/105.png";
import frame106 from "~assets/images/animation/106.png";
import frame107 from "~assets/images/animation/107.png";
import frame108 from "~assets/images/animation/108.png";
import frame109 from "~assets/images/animation/109.png";
import frame110 from "~assets/images/animation/110.png";
import frame111 from "~assets/images/animation/111.png";
import frame112 from "~assets/images/animation/112.png";
import frame113 from "~assets/images/animation/113.png";
import frame114 from "~assets/images/animation/114.png";
import frame115 from "~assets/images/animation/115.png";
import frame116 from "~assets/images/animation/116.png";
import frame117 from "~assets/images/animation/117.png";
import frame118 from "~assets/images/animation/118.png";
import frame119 from "~assets/images/animation/119.png";
import frame120 from "~assets/images/animation/120.png";
import frame121 from "~assets/images/animation/121.png";
import frame122 from "~assets/images/animation/122.png";
import frame123 from "~assets/images/animation/123.png";
import frame124 from "~assets/images/animation/124.png";
import frame125 from "~assets/images/animation/125.png";
import frame126 from "~assets/images/animation/126.png";
import frame127 from "~assets/images/animation/127.png";
import frame128 from "~assets/images/animation/128.png";
import frame129 from "~assets/images/animation/129.png";
import frame130 from "~assets/images/animation/130.png";
import frame131 from "~assets/images/animation/131.png";
import frame132 from "~assets/images/animation/132.png";
import frame133 from "~assets/images/animation/133.png";
import frame134 from "~assets/images/animation/134.png";
import frame135 from "~assets/images/animation/135.png";
import frame136 from "~assets/images/animation/136.png";
import frame137 from "~assets/images/animation/137.png";
import frame138 from "~assets/images/animation/138.png";
import frame139 from "~assets/images/animation/139.png";
import frame140 from "~assets/images/animation/140.png";
import frame141 from "~assets/images/animation/141.png";
import frame142 from "~assets/images/animation/142.png";
import frame143 from "~assets/images/animation/143.png";
import frame144 from "~assets/images/animation/144.png";
import frame145 from "~assets/images/animation/145.png";

class LoadingComponent extends Component {
  state = {
    frame: 0,
    unloaded: false
  };

  frames = [
    frame0,
    frame1,
    frame2,
    frame3,
    frame4,
    frame5,
    frame6,
    frame7,
    frame8,
    frame9,
    frame10,
    frame11,
    frame12,
    frame13,
    frame14,
    frame15,
    frame16,
    frame17,
    frame18,
    frame19,
    frame20,
    frame21,
    frame22,
    frame23,
    frame24,
    frame25,
    frame26,
    frame27,
    frame28,
    frame29,
    frame30,
    frame31,
    frame32,
    frame33,
    frame34,
    frame35,
    frame36,
    frame37,
    frame38,
    frame39,
    frame40,
    frame41,
    frame42,
    frame43,
    frame44,
    frame45,
    frame46,
    frame47,
    frame48,
    frame49,
    frame50,
    frame51,
    frame52,
    frame53,
    frame54,
    frame55,
    frame56,
    frame57,
    frame58,
    frame59,
    frame60,
    frame61,
    frame62,
    frame63,
    frame64,
    frame65,
    frame66,
    frame67,
    frame68,
    frame69,
    frame70,
    frame71,
    frame72,
    frame73,
    frame74,
    frame75,
    frame76,
    frame77,
    frame78,
    frame79,
    frame80,
    frame81,
    frame82,
    frame83,
    frame84,
    frame85,
    frame86,
    frame87,
    frame88,
    frame89,
    frame90,
    frame91,
    frame92,
    frame93,
    frame94,
    frame95,
    frame96,
    frame97,
    frame98,
    frame99,
    frame100,
    frame101,
    frame102,
    frame103,
    frame104,
    frame105,
    frame106,
    frame107,
    frame108,
    frame109,
    frame110,
    frame111,
    frame112,
    frame113,
    frame114,
    frame115,
    frame116,
    frame117,
    frame118,
    frame119,
    frame120,
    frame121,
    frame122,
    frame123,
    frame124,
    frame125,
    frame126,
    frame127,
    frame128,
    frame129,
    frame130,
    frame131,
    frame132,
    frame133,
    frame134,
    frame135,
    frame136,
    frame137,
    frame138,
    frame139,
    frame140,
    frame141,
    frame142,
    frame143,
    frame144,
    frame145
  ];

  loadingInterval;

  componentDidMount() {
    disableBodyScroll();

    this.loadingInterval = setInterval(() => {
      this.setState(
        prevState => ({
          frame: prevState.frame + 1
        }),
        () => {
          if (this.state.frame > 144) {
            this.clearTime();

            this.setState({
              unloaded: true
            });

            setTimeout(() => {
              clearAllBodyScrollLocks();

              setTimeout(() => {
                this.props.appContext.setLoaded(true);
              });
            }, 300);
          }
        }
      );
    }, 42);
  }

  componentWillUnmount() {
    this.clearTime();
  }

  //

  clearTime = () => {
    clearInterval(this.loadingInterval);
  };

  //

  render() {
    const { frame, unloaded } = this.state;

    return (
      <Div100vh className="loading animation-appear fixed top-0 right-0 bottom-0 left-0 flex flex-col items-center justify-center pt-2 bg-white">
        <img
          className={`loading__image transition-opacity ${
            unloaded ? `unloaded` : ``
          } w-2/5 sm:w-full relative block`}
          src={this.frames[frame]}
          alt="Frame"
        />
      </Div100vh>
    );
  }
}

const Loading = () => {
  const appContext = useContext(AppContext);

  return <LoadingComponent appContext={appContext} />;
};

export default Loading;
