/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */
// import { PropTypes } from "prop-types";

import React, { Component, createRef, useContext } from "react";
import _ from "underscore";
import { graphql } from "gatsby";
import Div100vh from "react-div-100vh";
import { AppContext } from "~context/AppContext";
import { CursorContext } from "~context/CursorContext";
import { DocumentContext } from "~context/DocumentContext";
import Cursor from "~components/Cursor";
import Header from "~components/Header";
import Icon from "~components/svg/Icon";
import Layout from "~components/Layout";
import Loading from "~components/Loading";
import SEO from "~components/SEO";
import Ticker from "~components/Ticker";

class IndexPageComponent extends Component {
  state = {
    clients: []
  };

  clientInterval;

  fixedSwitchRef = createRef();

  throttledHandleScroll;

  timeouts = [];

  componentDidMount() {
    const { frontmatter } = this.props;

    this.setState({
      clients: frontmatter.clients
    });
  }

  componentWillUnmount() {
    clearInterval(this.clientInterval);

    this.timeouts.forEach(timeout => {
      clearTimeout(timeout);
    });
  }

  //

  render() {
    const {
      appContext,
      cursorContext,
      documentContext,
      frontmatter,
      location
    } = this.props;
    const { clients } = this.state;
    const { device, scrollTop, windowHeight } = documentContext;

    //

    return (
      <>
        <SEO
          customTitle={frontmatter.title}
          customDescription={frontmatter.seoDescription}
          customKeywords={frontmatter.seoKeywords}
          path={location.pathname}
        />

        {(!appContext.loaded && <Loading />) || (
          <>
            {(device === `desktop` && (
              <>
                <Cursor />

                <Header />

                <article className="index-page__contact-widget animation-appear-left animation-delay-2 fixed right-0 z-40 pr-12 flex flex-col justify-between text-onyx">
                  <div>
                    <p className="b1">Brand Activation:</p>
                    <p className="mb-12 b1">Experiential + Digital</p>

                    <p className="b1">Level 2, 69</p>
                    <p className="b1">Reservoir Street</p>
                    <p className="mb-12 b1">Surry Hills, NSW 2010</p>

                    <p className="b1">
                      <a
                        href="mailto:hello@commonproject.agency"
                        className="py-4 underline"
                        onMouseEnter={() => {
                          cursorContext.setHovering(true);
                        }}
                        onMouseLeave={() => {
                          cursorContext.setHovering(false);
                        }}
                      >
                        hello@commonproject.agency
                      </a>
                    </p>

                    <h3 className="index-page__contact-widget__copyright absolute b1 text-white">
                      © 2020
                    </h3>
                  </div>
                </article>

                <Layout className="index-page relative bg-white text-onyx">
                  <section className="index-page__clients w-screen relative flex items-center justify-center sm:block pb-64 sm:pb-24">
                    <div className="grid">
                      <ul className="grid-end-9 sm:grid-end-10">
                        {clients.map((client, index) => {
                          const key = `${client.name}-${index}`;

                          return (
                            <li
                              key={key}
                              className="animation-appear w-full relative mt-10 mb-10 sm:mb-4 sm:mt-2"
                              style={{
                                animationDelay: `${index * 50 + 500}ms`
                              }}
                            >
                              <h2 className="f1 uppercase">{client.name}</h2>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </section>

                  <section className="index-page__snapper w-screen relative">
                    <div className="relative grid items-start">
                      <div className="index-page__snapper__sticky grid-end-8 sticky left-0">
                        <h3 className="f2 uppercase">
                          THE BIGGEST BRANDS, BROUGHT TO LIFE.
                        </h3>

                        <h3 className="f2 uppercase">EXPERIENTIAL</h3>
                      </div>

                      <div className="grid-end-4 h-screen relative"></div>
                    </div>
                  </section>

                  <div className="index-page__contact w-screen relative bg-onyx text-white">
                    <div className="w-full h-full absolute top-0 right-0 bottom-0 left-0 overflow-hidden clipped">
                      <Ticker color="white" />
                    </div>

                    <div className="w-full h-full absolute top-0 right-0 bottom-0 left-0 z-40 clipped overflow-hidden pointer-events-none">
                      <div className="index-page__contact__digital w-full relative pt-2">
                        <div className="grid">
                          <h3 className="grid-end-10 f2 text-white uppercase">
                            + DIGITAL
                          </h3>
                        </div>
                      </div>

                      <Header color="white" />

                      {scrollTop > windowHeight && <Ticker color="white" />}

                      <article className="index-page__contact-widget animation-appear-left animation-delay-2 fixed right-0 z-40 pr-12 flex flex-col text-white">
                        <div>
                          <p className="b1">Brand Activation:</p>
                          <p className="mb-12 b1">Experiential + Digital</p>

                          <p className="b1">Level 2, 69</p>
                          <p className="b1">Reservoir Street</p>
                          <p className="mb-12 b1">Surry Hills, NSW 2010</p>

                          <p className="mb-12 b1">
                            <a
                              href="mailto:hello@commonproject.agency"
                              className="py-4 underline"
                              onMouseEnter={() => {
                                cursorContext.setHovering(true);
                              }}
                              onMouseLeave={() => {
                                cursorContext.setHovering(false);
                              }}
                            >
                              hello@commonproject.agency
                            </a>
                          </p>
                        </div>

                        <h3 className="index-page__contact-widget b1 text-white">
                          <span className="py-2">Made with</span>

                          <a
                            className="index-page__lam py-2 underline"
                            href="https://www.loveandmoney.agency"
                            target="_blank"
                            rel="noopener noreferrer"
                            onMouseEnter={() => {
                              cursorContext.setHovering(true);
                            }}
                            onMouseLeave={() => {
                              cursorContext.setHovering(false);
                            }}
                          >
                            Love + Money
                          </a>
                        </h3>
                      </article>
                    </div>

                    <div className="grid">
                      <article className="grid-end-9 sm:grid-end-10 sm:mb-12">
                        <h2 className="sm:mt-10 f2 uppercase">
                          Want to see our creds?
                        </h2>

                        <h2 className="f2 uppercase">
                          <a
                            href="mailto:hello@commonproject.agency"
                            className="py-4 underline"
                            onMouseEnter={() => {
                              cursorContext.setHovering(true);
                            }}
                            onMouseLeave={() => {
                              cursorContext.setHovering(false);
                            }}
                          >
                            Just ask.
                          </a>
                        </h2>
                      </article>
                    </div>

                    <div className="index-page__meta absolute left-0 right-0">
                      <div className="grid">
                        <div className="grid-end-12">
                          <Icon className="w-12 sm:w-12" />
                        </div>
                      </div>
                    </div>
                  </div>
                </Layout>
              </>
            )) || (
              <Div100vh>
                <div className="index-page__touch-flow w-full h-full relative overflow-y-scroll">
                  <Layout className="index-page relative bg-white text-onyx">
                    <div className="index-page__clients w-screen relative flex items-center justify-center sm:block sm:pb-24">
                      <div className="w-full h-full absolute top-0 right-0 bottom-0 left-0 overflow-hidden clipped">
                        <Ticker color="black" />
                      </div>

                      <div className="grid">
                        <ul className="grid-end-9 sm:grid-end-10">
                          {clients.map((client, index) => {
                            const key = `${client.name}-${index}`;

                            return (
                              <li
                                key={key}
                                className="animation-fade-in w-full relative mt-10 mb-10 sm:mb-4 sm:mt-2"
                                style={{
                                  animationDelay: `${index * 50 + 500}ms`
                                }}
                              >
                                <h2 className="f1 uppercase">{client.name}</h2>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>

                    <section className="index-page__snapper w-screen relative">
                      <div className="relative grid items-start">
                        <div className="index-page__snapper__sticky grid-end-8 sticky left-0">
                          <h3 className="f2 uppercase">
                            THE BIGGEST BRANDS, BROUGHT TO LIFE.
                          </h3>

                          <h3 className="f2 uppercase">EXPERIENTIAL</h3>
                        </div>

                        <div className="grid-end-4 h-screen relative"></div>
                      </div>
                    </section>

                    <div className="index-page__contact w-screen relative bg-onyx text-white">
                      <div className="w-full h-full absolute top-0 right-0 bottom-0 left-0 overflow-hidden clipped">
                        <Ticker color="white" />
                      </div>

                      <div className="w-full h-full absolute top-0 right-0 bottom-0 left-0 z-40 clipped overflow-hidden pointer-events-none">
                        <div className="index-page__contact__digital w-full relative pt-2">
                          <div className="grid">
                            <h3 className="grid-end-10 f2 text-white uppercase">
                              + DIGITAL
                            </h3>
                          </div>
                        </div>

                        <Header color="white" />

                        {scrollTop > windowHeight && <Ticker color="white" />}
                      </div>

                      <div className="grid">
                        <article className="grid-end-9 sm:grid-end-10 sm:mb-12">
                          <h3 className="f2 uppercase">
                            THE BIGGEST BRANDS, BROUGHT TO LIFE.
                          </h3>

                          <h3 className="f2 uppercase">EXPERIENTIAL</h3>

                          <h3 className="f2 uppercase">+ DIGITAL</h3>

                          <h2 className="sm:mt-10 f2 uppercase">
                            Want to see our creds?
                          </h2>

                          <h2 className="f2 uppercase">
                            <a
                              href="mailto:hello@commonproject.agency"
                              className="py-4 underline"
                              onMouseEnter={() => {
                                cursorContext.setHovering(true);
                              }}
                              onMouseLeave={() => {
                                cursorContext.setHovering(false);
                              }}
                            >
                              Just ask.
                            </a>
                          </h2>
                        </article>

                        <article className="sm:grid-end-10">
                          <p className="b1">Level 2, 69</p>
                          <p className="b1">Reservoir Street</p>
                          <p className="mb-12 b1">Surry Hills, NSW 2010</p>

                          <p className="b1">
                            <a
                              href="mailto:hello@commonproject.agency"
                              className="py-4 underline break-all"
                            >
                              hello@commonproject.agency
                            </a>
                          </p>

                          <p className="mt-8 b1">
                            <span className="py-2">Made with</span>

                            <a
                              className="ml-2 py-2 underline"
                              href="https://www.loveandmoney.agency"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              &lt;3 + $
                            </a>
                          </p>
                        </article>
                      </div>

                      <div className="index-page__meta absolute left-0 bottom-0 right-0">
                        <div className="grid">
                          <div className="grid-end-10 flex items-center justify-between">
                            <Icon className="w-12 h-10" />

                            <h3 className="mr-2 b1 text-white">© 2020</h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Layout>
                </div>
              </Div100vh>
            )}
          </>
        )}
      </>
    );
  }
}

//

const IndexPage = ({ data, location }) => {
  const appContext = useContext(AppContext);
  const cursorContext = useContext(CursorContext);
  const documentContext = useContext(DocumentContext);
  const { frontmatter } = data.markdownRemark;

  return (
    <IndexPageComponent
      appContext={appContext}
      cursorContext={cursorContext}
      documentContext={documentContext}
      frontmatter={frontmatter}
      location={location}
    />
  );
};

export default IndexPage;

export const query = graphql`
  query IndexPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        clients {
          name
        }
        seoDescription
        seoKeywords
      }
    }
  }
`;
