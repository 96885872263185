/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */

import React from "react";
import TickerText from "~components/svg/TickerText";

const Ticker = ({ color }) => {
  let backgroundColor = `white`;

  if (!color) {
    color = `onyx`;
  }

  if (color === `white`) {
    backgroundColor = `onyx`;
  }

  return (
    <div
      className={`ticker w-full h-24 fixed bottom-0 right-0 left-0 flex z-40 py-4 sm:py-0 overflow-x-hidden gpu bg-${backgroundColor}`}
    >
      <TickerText
        className="ticker__svg h-full relative block gpu"
        color={color}
      />
      <TickerText
        className="ticker__svg h-full relative block gpu"
        color={color}
      />
    </div>
  );
};

export default Ticker;
