/* eslint-disable react/prop-types */

import React, { Component, useContext } from "react";
// import Div100vh from "react-div-100vh";
import { AppContext } from "~context/AppContext";
import { DocumentContext } from "~context/DocumentContext";
import Logo from "~components/svg/Logo";

class HeaderComponent extends Component {
  componentDidMount() {}

  //

  render() {
    const { documentContext } = this.props;
    const { device } = documentContext;

    let { color } = this.props;

    if (!color) {
      color = `#1F1F1F`;
    }

    let backgroundColor = `white`;

    if (color === `white`) {
      backgroundColor = `onyx`;
    }

    if (device === `desktop`) {
      return (
        <header
          className={`header animation-appear-down animation-delay-3 w-full fixed top-0 right-0 left-0 z-40 pt-2 bg-${backgroundColor}`}
        >
          <Logo className="w-full relative" color={color} />
        </header>
      );
    }

    return <></>;
  }
}

const Header = ({ color }) => {
  const appContext = useContext(AppContext);
  const documentContext = useContext(DocumentContext);

  return (
    <HeaderComponent
      appContext={appContext}
      color={color}
      documentContext={documentContext}
    />
  );
};

export default Header;
