import React from "react";
import PropTypes from "prop-types";

const TickerText = ({ className, color }) => (
  <svg className={className} viewBox="0 0 2883 64" fill="none">
    <g>
      <path
        d="M134.218 60.3547V3.16273H120.353L102.111 54.7172L83.869 3.16273H70.0859V60.3547H77.545V7.16617L96.4358 60.3547H107.787L126.758 7.16617V60.3547H134.218Z"
        fill={color}
      />
      <path
        d="M208.19 60.3547V3.16273H194.326L176.084 54.7172L157.842 3.16273H144.059V60.3547H151.518V7.16617L170.409 60.3547H181.759L200.731 7.16617V60.3547H208.19Z"
        fill={color}
      />
      <path
        d="M215.518 31.7587C215.518 48.2627 228.166 61.1718 244.787 61.1718C261.164 61.1718 274.137 48.5895 274.137 31.7587C274.137 15.1731 261.489 2.3457 244.787 2.3457C228.409 2.3457 215.518 14.9279 215.518 31.7587ZM223.302 31.8404C223.302 19.1765 231.734 9.45385 244.787 9.45385C257.597 9.45385 266.272 18.768 266.272 31.7587C266.272 44.341 257.759 54.0636 244.787 54.0636C232.139 54.0636 223.302 44.6678 223.302 31.8404Z"
        fill={color}
      />
      <path
        d="M288.854 8.39172L318.285 60.3547H331.176V3.16273H323.717V55.1258L294.205 3.16273H281.395V60.3547H288.854V8.39172Z"
        fill={color}
      />
      <path
        d="M365.937 36.8243H383.531C393.422 36.8243 401.935 30.6966 401.935 19.9935C401.935 9.29045 393.908 3.16273 383.531 3.16273H358.478V60.3547H365.937V36.8243ZM365.937 29.7162V10.2709H382.882C389.044 10.2709 394.07 13.2939 394.07 19.9935C394.07 26.6932 389.044 29.7162 382.882 29.7162H365.937Z"
        fill={color}
      />
      <path
        d="M506.011 3.16273V39.8473C506.011 47.7725 503.092 54.0636 494.336 54.0636C487.525 54.0636 482.093 49.8151 482.093 42.6252C482.093 39.7656 482.742 37.2328 483.958 35.0269L476.904 32.3307C475.364 35.1903 474.634 38.4584 474.634 42.2167C474.634 53.9002 482.661 61.1718 494.336 61.1718C506.416 61.1718 513.47 53.5734 513.47 41.2363V3.16273H506.011Z"
        fill={color}
      />
      <path
        d="M522.766 3.16273V60.3547H563.547V53.2466H530.225V34.2915H560.304V27.1834H530.225V10.2709H562.736V3.16273H522.766Z"
        fill={color}
      />
      <path
        d="M595.919 9.20874C605.649 9.20874 612.216 15.0096 614.243 24.4872L621.053 21.3825C617.729 9.04534 608.486 2.3457 595.919 2.3457C579.461 2.3457 567.543 15.0914 567.543 31.7587C567.543 48.5078 578.569 61.1718 595.919 61.1718C610.027 61.1718 620.972 52.9198 624.134 38.7035L617.567 36.0073C614.972 47.6091 608 54.3087 595.919 54.3087C582.866 54.3087 575.326 44.7495 575.326 31.7587C575.326 19.5033 582.866 9.20874 595.919 9.20874Z"
        fill={color}
      />
      <path
        d="M672.656 3.16273H623.199V10.2709H644.198V60.3547H651.657V10.2709H672.656V3.16273Z"
        fill={color}
      />
      <path
        d="M438.767 63.0803C456.233 63.0803 470.065 49.4223 470.065 31.5401C470.065 13.9396 456.512 0 438.767 0C421.302 0 407.469 13.658 407.469 31.5401C407.469 48.9999 421.162 63.0803 438.767 63.0803ZM438.767 57.7297C424.236 57.7297 412.918 46.3246 412.918 31.5401C412.918 16.8965 423.956 5.35056 438.767 5.35056C453.299 5.35056 464.616 16.6149 464.616 31.5401C464.616 46.1838 453.578 57.7297 438.767 57.7297ZM426.332 49.1407H432.06V34.0746H438.627C443.657 34.0746 445.614 36.0459 445.614 41.1148C445.614 43.3677 445.753 45.0573 446.033 46.043C446.173 47.1694 446.592 48.155 447.011 49.1407H453.857V48.2958C453.159 47.5918 452.6 46.6062 452.46 45.6206C452.181 44.6349 452.041 43.0861 452.041 41.1148C452.041 36.1867 449.945 33.2298 445.753 32.2442C450.364 30.8361 452.879 27.316 452.879 22.5287C452.879 20.135 451.901 18.1637 449.945 16.3333C447.849 14.6436 445.194 13.7988 441.841 13.7988H426.332V49.1407ZM432.06 29.1465V18.727H441.003C442.819 18.727 444.216 19.1494 445.334 19.9942C446.312 20.839 446.871 22.1063 446.871 23.9367C446.871 25.7672 446.312 27.0344 445.334 27.8792C444.216 28.7241 442.819 29.1465 441.003 29.1465H432.06Z"
        fill={color}
      />
      <path
        d="M31.2984 63.0803C48.764 63.0803 62.5967 49.4223 62.5967 31.5401C62.5967 13.9396 49.0434 0 31.2984 0C13.8328 0 0 13.658 0 31.5401C0 48.9999 13.693 63.0803 31.2984 63.0803ZM31.2984 57.7297C16.767 57.7297 5.44927 46.3246 5.44927 31.5401C5.44927 16.8965 16.4875 5.35056 31.2984 5.35056C45.8298 5.35056 57.1475 16.6149 57.1475 31.5401C57.1475 46.1838 46.1092 57.7297 31.2984 57.7297ZM31.5778 49.8447C40.6599 49.8447 47.0873 44.3533 49.1832 35.6235L43.8736 33.6522C42.4764 40.5516 38.8435 44.4941 31.5778 44.4941C24.1724 44.4941 19.4218 38.5804 19.4218 31.3993C19.4218 24.2183 23.7532 18.4453 31.1586 18.4453C36.8874 18.4453 41.3586 21.6838 42.1969 27.5976L47.227 26.0488C45.8298 17.6005 39.4024 13.2356 31.1586 13.2356C21.0985 13.2356 13.4136 21.2614 13.4136 31.3993C13.4136 42.1005 20.9587 49.8447 31.5778 49.8447Z"
        fill={color}
      />
      <path
        d="M686.353 19.4583H689.244V6.21829H694.776V3.55762H680.758V6.21829H686.353V19.4583ZM696.474 19.4583H699.365V5.5848L703.766 19.4583H707.726L712.127 5.5848V19.4583H715.081V3.55762H709.801L705.778 16.7976L701.754 3.55762H696.474V19.4583Z"
        fill={color}
      />
      <path
        d="M763.081 62.9999H792.254C801.835 62.9999 811.761 57.3034 811.761 46.6871C811.761 39.0917 807.704 34.0856 800.54 31.8415C806.064 29.2522 809.603 24.5051 809.603 18.2907C809.603 7.84702 800.022 2.58203 790.442 2.58203H763.081V62.9999ZM771.022 28.3891V10.0911H790.787C796.311 10.0911 801.748 12.8531 801.748 19.0675C801.748 25.5408 796.742 28.3891 790.787 28.3891H771.022ZM771.022 55.4908V35.4666H791.995C798.123 35.4666 803.475 39.0917 803.475 45.6513C803.475 52.2973 798.037 55.4908 791.995 55.4908H771.022Z"
        fill={color}
      />
      <path
        d="M880.536 62.9999H889.253L895.554 47.6365H927.144L933.358 62.9999H942.162L916.873 2.58203H905.825L880.536 62.9999ZM898.402 40.3864L911.349 8.88275L924.209 40.3864H898.402Z"
        fill={color}
      />
      <path
        d="M957.72 8.10595L989.051 62.9999H1002.77V2.58203H994.834V57.476L963.417 2.58203H949.78V62.9999H957.72V8.10595Z"
        fill={color}
      />
      <path
        d="M1015.86 62.9999H1038.3C1056.26 62.9999 1068.17 51.5205 1068.17 33.0499C1068.17 14.9245 1057.29 2.58203 1038.3 2.58203H1015.86V62.9999ZM1023.8 9.83217H1038.3C1052.63 9.83217 1060.23 19.1538 1060.23 33.0499C1060.23 46.3418 1052.29 55.7498 1038.3 55.7498H1023.8V9.83217Z"
        fill={color}
      />
      <path
        d="M1136.08 2.58203V62.9999H1179.5V55.4908H1144.02V35.4666H1176.04V27.9575H1144.02V10.0911H1178.63V2.58203H1136.08Z"
        fill={color}
      />
      <path
        d="M1185.69 62.9999H1195.1L1213.23 36.416L1231.35 62.9999H1240.76L1217.89 30.2016L1237.31 2.58203H1227.99L1213.23 24.3325L1198.81 2.58203H1189.15L1208.57 30.2016L1185.69 62.9999Z"
        fill={color}
      />
      <path
        d="M1256.8 38.1423H1275.53C1286.06 38.1423 1295.12 31.6689 1295.12 20.3621C1295.12 9.05538 1286.58 2.58203 1275.53 2.58203H1248.86V62.9999H1256.8V38.1423ZM1256.8 30.6332V10.0911H1274.84C1281.4 10.0911 1286.75 13.2846 1286.75 20.3621C1286.75 27.4397 1281.4 30.6332 1274.84 30.6332H1256.8Z"
        fill={color}
      />
      <path
        d="M1305.67 2.58203V62.9999H1349.08V55.4908H1313.61V35.4666H1345.63V27.9575H1313.61V10.0911H1348.22V2.58203H1305.67Z"
        fill={color}
      />
      <path d="M1431.1 62.9999H1439.04V2.58203H1431.1V62.9999Z" fill={color} />
      <path
        d="M1452.09 2.58203V62.9999H1495.51V55.4908H1460.03V35.4666H1492.05V27.9575H1460.03V10.0911H1494.64V2.58203H1452.09Z"
        fill={color}
      />
      <path
        d="M1514.65 8.10595L1545.98 62.9999H1559.71V2.58203H1551.76V57.476L1520.35 2.58203H1506.71V62.9999H1514.65V8.10595Z"
        fill={color}
      />
      <path
        d="M1636.26 2.58203V62.9999H1679.68V55.4908H1644.2V35.4666H1676.22V27.9575H1644.2V10.0911H1678.81V2.58203H1636.26Z"
        fill={color}
      />
      <path
        d="M850.82 63.0803C868.285 63.0803 882.118 49.4223 882.118 31.5401C882.118 13.9396 868.565 0 850.82 0C833.354 0 819.521 13.658 819.521 31.5401C819.521 48.9999 833.215 63.0803 850.82 63.0803ZM850.82 57.7297C836.288 57.7297 824.971 46.3246 824.971 31.5401C824.971 16.8965 836.009 5.35056 850.82 5.35056C865.351 5.35056 876.669 16.6149 876.669 31.5401C876.669 46.1838 865.631 57.7297 850.82 57.7297ZM838.384 49.1407H844.113V34.0746H850.68C855.71 34.0746 857.666 36.0459 857.666 41.1148C857.666 43.3677 857.806 45.0573 858.086 46.043C858.225 47.1694 858.644 48.155 859.064 49.1407H865.91V48.2958C865.212 47.5918 864.653 46.6062 864.513 45.6206C864.233 44.6349 864.094 43.0861 864.094 41.1148C864.094 36.1867 861.998 33.2298 857.806 32.2442C862.417 30.8361 864.932 27.316 864.932 22.5287C864.932 20.135 863.954 18.1637 861.998 16.3333C859.902 14.6436 857.247 13.7988 853.894 13.7988H838.384V49.1407ZM844.113 29.1465V18.727H853.055C854.872 18.727 856.269 19.1494 857.387 19.9942C858.365 20.839 858.924 22.1063 858.924 23.9367C858.924 25.7672 858.365 27.0344 857.387 27.8792C856.269 28.7241 854.872 29.1465 853.055 29.1465H844.113Z"
        fill={color}
      />
      <path
        d="M1389.82 63.0803C1407.29 63.0803 1421.12 49.4223 1421.12 31.5401C1421.12 13.9396 1407.56 0 1389.82 0C1372.35 0 1358.52 13.658 1358.52 31.5401C1358.52 48.9999 1372.21 63.0803 1389.82 63.0803ZM1389.82 57.7297C1375.29 57.7297 1363.97 46.3246 1363.97 31.5401C1363.97 16.8965 1375.01 5.35056 1389.82 5.35056C1404.35 5.35056 1415.67 16.6149 1415.67 31.5401C1415.67 46.1838 1404.63 57.7297 1389.82 57.7297ZM1377.38 49.1407H1383.11V34.0746H1389.68C1394.71 34.0746 1396.67 36.0459 1396.67 41.1148C1396.67 43.3677 1396.81 45.0573 1397.09 46.043C1397.23 47.1694 1397.64 48.155 1398.06 49.1407H1404.91V48.2958C1404.21 47.5918 1403.65 46.6062 1403.51 45.6206C1403.23 44.6349 1403.09 43.0861 1403.09 41.1148C1403.09 36.1867 1401 33.2298 1396.81 32.2442C1401.42 30.8361 1403.93 27.316 1403.93 22.5287C1403.93 20.135 1402.95 18.1637 1401 16.3333C1398.9 14.6436 1396.25 13.7988 1392.89 13.7988H1377.38V49.1407ZM1383.11 29.1465V18.727H1392.06C1393.87 18.727 1395.27 19.1494 1396.39 19.9942C1397.37 20.839 1397.92 22.1063 1397.92 23.9367C1397.92 25.7672 1397.37 27.0344 1396.39 27.8792C1395.27 28.7241 1393.87 29.1465 1392.06 29.1465H1383.11Z"
        fill={color}
      />
      <path
        d="M1596.82 63.0803C1614.29 63.0803 1628.12 49.4223 1628.12 31.5401C1628.12 13.9396 1614.56 0 1596.82 0C1579.35 0 1565.52 13.658 1565.52 31.5401C1565.52 48.9999 1579.21 63.0803 1596.82 63.0803ZM1596.82 57.7297C1582.29 57.7297 1570.97 46.3246 1570.97 31.5401C1570.97 16.8965 1582.01 5.35056 1596.82 5.35056C1611.35 5.35056 1622.67 16.6149 1622.67 31.5401C1622.67 46.1838 1611.63 57.7297 1596.82 57.7297ZM1597.1 49.8447C1606.18 49.8447 1612.61 44.3533 1614.7 35.6235L1609.4 33.6522C1608 40.5516 1604.36 44.4941 1597.1 44.4941C1589.69 44.4941 1584.94 38.5804 1584.94 31.3993C1584.94 24.2183 1589.27 18.4453 1596.68 18.4453C1602.41 18.4453 1606.88 21.6838 1607.72 27.5976L1612.75 26.0488C1611.35 17.6005 1604.92 13.2356 1596.68 13.2356C1586.62 13.2356 1578.94 21.2614 1578.94 31.3993C1578.94 42.1005 1586.48 49.8447 1597.1 49.8447Z"
        fill={color}
      />
      <path
        d="M1079.12 19.4583H1082.01V6.21829H1087.54V3.55762H1073.52V6.21829H1079.12V19.4583ZM1089.24 19.4583H1092.13V5.5848L1096.53 19.4583H1100.49L1104.89 5.5848V19.4583H1107.85V3.55762H1102.56L1098.54 16.7976L1094.52 3.55762H1089.24V19.4583Z"
        fill={color}
      />
      <path
        d="M1801.64 36.683H1819.23C1829.12 36.683 1837.63 30.5893 1837.63 19.9455C1837.63 9.30176 1829.61 3.20801 1819.23 3.20801H1794.18V60.083H1801.64V36.683ZM1801.64 29.6143V10.2768H1818.58C1824.74 10.2768 1829.77 13.283 1829.77 19.9455C1829.77 26.608 1824.74 29.6143 1818.58 29.6143H1801.64Z"
        fill={color}
      />
      <path
        d="M1758.59 63C1775.85 63 1789.51 49.3594 1789.51 31.5C1789.51 13.9219 1776.12 0 1758.59 0C1741.34 0 1727.68 13.6406 1727.68 31.5C1727.68 48.9375 1741.2 63 1758.59 63ZM1758.59 57.6563C1744.24 57.6563 1733.06 46.2656 1733.06 31.5C1733.06 16.875 1743.96 5.34375 1758.59 5.34375C1772.95 5.34375 1784.13 16.5938 1784.13 31.5C1784.13 46.125 1773.22 57.6563 1758.59 57.6563ZM1758.87 49.7813C1767.84 49.7813 1774.19 44.2969 1776.26 35.5781L1771.01 33.6094C1769.63 40.5 1766.05 44.4375 1758.87 44.4375C1751.55 44.4375 1746.86 38.5313 1746.86 31.3594C1746.86 24.1875 1751.14 18.4219 1758.45 18.4219C1764.11 18.4219 1768.53 21.6562 1769.36 27.5625L1774.33 26.0156C1772.95 17.5781 1766.6 13.2187 1758.45 13.2187C1748.52 13.2187 1740.93 21.2344 1740.93 31.3594C1740.93 42.0469 1748.38 49.7813 1758.87 49.7813Z"
        fill={color}
      />
      <path
        d="M1847.86 19.2503H1850.74V6.37865H1856.24V3.79199H1842.3V6.37865H1847.86V19.2503ZM1857.93 19.2503H1860.8V5.76278L1865.18 19.2503H1869.11L1873.49 5.76278V19.2503H1876.43V3.79199H1871.18L1867.18 16.6637L1863.18 3.79199H1857.93V19.2503Z"
        fill={color}
      />
      <g clipPath="url(#clip1)">
        <path
          d="M1924.85 2.62207V63.0399H1968.27V55.5309H1932.79V35.5067H1964.82V27.9976H1932.79V10.1311H1967.4V2.62207H1924.85Z"
          fill={color}
        />
        <path
          d="M1974.47 63.0399H1983.87L2002 36.4561L2020.12 63.0399H2029.53L2006.66 30.2417L2026.08 2.62207H2016.76L2002 24.3725L1987.59 2.62207H1977.92L1997.34 30.2417L1974.47 63.0399Z"
          fill={color}
        />
        <path
          d="M2045.57 38.1823H2064.3C2074.83 38.1823 2083.89 31.709 2083.89 20.4022C2083.89 9.09542 2075.35 2.62207 2064.3 2.62207H2037.63V63.0399H2045.57V38.1823ZM2045.57 30.6732V10.1311H2063.61C2070.17 10.1311 2075.52 13.3247 2075.52 20.4022C2075.52 27.4797 2070.17 30.6732 2063.61 30.6732H2045.57Z"
          fill={color}
        />
        <path
          d="M2094.44 2.62207V63.0399H2137.86V55.5309H2102.38V35.5067H2134.4V27.9976H2102.38V10.1311H2136.99V2.62207H2094.44Z"
          fill={color}
        />
        <path
          d="M2219.88 63.0399H2227.82V2.62207H2219.88V63.0399Z"
          fill={color}
        />
        <path
          d="M2240.86 2.62207V63.0399H2284.28V55.5309H2248.8V35.5067H2280.83V27.9976H2248.8V10.1311H2283.42V2.62207H2240.86Z"
          fill={color}
        />
        <path
          d="M2303.42 8.14599L2334.75 63.0399H2348.48V2.62207H2340.54V57.516L2309.12 2.62207H2295.48V63.0399H2303.42V8.14599Z"
          fill={color}
        />
        <path
          d="M2425.03 2.62207V63.0399H2468.45V55.5309H2432.97V35.5067H2465V27.9976H2432.97V10.1311H2467.59V2.62207H2425.03Z"
          fill={color}
        />
        <path
          d="M2178.59 63.1203C2196.06 63.1203 2209.89 49.4623 2209.89 31.5802C2209.89 13.9797 2196.34 0.0400391 2178.59 0.0400391C2161.13 0.0400391 2147.29 13.698 2147.29 31.5802C2147.29 49.0399 2160.99 63.1203 2178.59 63.1203ZM2178.59 57.7698C2164.06 57.7698 2152.74 46.3646 2152.74 31.5802C2152.74 16.9365 2163.78 5.3906 2178.59 5.3906C2193.12 5.3906 2204.44 16.6549 2204.44 31.5802C2204.44 46.2238 2193.4 57.7698 2178.59 57.7698ZM2166.16 49.1807H2171.89V34.1147H2178.45C2183.48 34.1147 2185.44 36.0859 2185.44 41.1549C2185.44 43.4077 2185.58 45.0974 2185.86 46.083C2186 47.2094 2186.42 48.1951 2186.84 49.1807H2193.68V48.3359C2192.98 47.6319 2192.43 46.6462 2192.29 45.6606C2192.01 44.675 2191.87 43.1261 2191.87 41.1549C2191.87 36.2267 2189.77 33.2698 2185.58 32.2842C2190.19 30.8762 2192.7 27.3561 2192.7 22.5687C2192.7 20.175 2191.73 18.2038 2189.77 16.3733C2187.67 14.6837 2185.02 13.8388 2181.67 13.8388H2166.16V49.1807ZM2171.89 29.1865V18.767H2180.83C2182.64 18.767 2184.04 19.1894 2185.16 20.0342C2186.14 20.8791 2186.7 22.1463 2186.7 23.9768C2186.7 25.8072 2186.14 27.0744 2185.16 27.9193C2184.04 28.7641 2182.64 29.1865 2180.83 29.1865H2171.89Z"
          fill={color}
        />
        <path
          d="M2385.59 63.1203C2403.06 63.1203 2416.89 49.4623 2416.89 31.5802C2416.89 13.9797 2403.34 0.0400391 2385.59 0.0400391C2368.13 0.0400391 2354.29 13.698 2354.29 31.5802C2354.29 49.0399 2367.99 63.1203 2385.59 63.1203ZM2385.59 57.7698C2371.06 57.7698 2359.74 46.3646 2359.74 31.5802C2359.74 16.9365 2370.78 5.3906 2385.59 5.3906C2400.12 5.3906 2411.44 16.6549 2411.44 31.5802C2411.44 46.2238 2400.4 57.7698 2385.59 57.7698ZM2385.87 49.8847C2394.95 49.8847 2401.38 44.3934 2403.48 35.6635L2398.17 33.6922C2396.77 40.5917 2393.14 44.5342 2385.87 44.5342C2378.47 44.5342 2373.72 38.6204 2373.72 31.4394C2373.72 24.2584 2378.05 18.4854 2385.45 18.4854C2391.18 18.4854 2395.65 21.7239 2396.49 27.6377L2401.52 26.0888C2400.12 17.6406 2393.7 13.2756 2385.45 13.2756C2375.39 13.2756 2367.71 21.3015 2367.71 31.4394C2367.71 42.1405 2375.25 49.8847 2385.87 49.8847Z"
          fill={color}
        />
        <path
          d="M2490.45 63.0399H2519.62C2529.2 63.0399 2539.13 57.3434 2539.13 46.7271C2539.13 39.1317 2535.07 34.1257 2527.91 31.8816C2533.43 29.2922 2536.97 24.5451 2536.97 18.3307C2536.97 7.88706 2527.39 2.62207 2517.81 2.62207H2490.45V63.0399ZM2498.39 28.4291V10.1311H2518.15C2523.68 10.1311 2529.12 12.8931 2529.12 19.1075C2529.12 25.5809 2524.11 28.4291 2518.15 28.4291H2498.39ZM2498.39 55.5309V35.5067H2519.36C2525.49 35.5067 2530.84 39.1317 2530.84 45.6914C2530.84 52.3373 2525.4 55.5309 2519.36 55.5309H2498.39Z"
          fill={color}
        />
        <path
          d="M2607.9 63.0399H2616.62L2622.92 47.6765H2654.51L2660.73 63.0399H2669.53L2644.24 2.62207H2633.19L2607.9 63.0399ZM2625.77 40.4264L2638.72 8.92279L2651.58 40.4264H2625.77Z"
          fill={color}
        />
        <path
          d="M2685.09 8.14599L2716.42 63.0399H2730.14V2.62207H2722.2V57.516L2690.78 2.62207H2677.15V63.0399H2685.09V8.14599Z"
          fill={color}
        />
        <path
          d="M2743.23 63.0399H2765.67C2783.62 63.0399 2795.53 51.5605 2795.53 33.0899C2795.53 14.9646 2784.66 2.62207 2765.67 2.62207H2743.23V63.0399ZM2751.17 9.87221H2765.67C2780 9.87221 2787.59 19.1938 2787.59 33.0899C2787.59 46.3819 2779.65 55.7898 2765.67 55.7898H2751.17V9.87221Z"
          fill={color}
        />
        <path
          d="M2578.19 63.1203C2595.65 63.1203 2609.49 49.4623 2609.49 31.5802C2609.49 13.9797 2595.93 0.0400391 2578.19 0.0400391C2560.72 0.0400391 2546.89 13.698 2546.89 31.5802C2546.89 49.0399 2560.58 63.1203 2578.19 63.1203ZM2578.19 57.7698C2563.66 57.7698 2552.34 46.3646 2552.34 31.5802C2552.34 16.9365 2563.38 5.3906 2578.19 5.3906C2592.72 5.3906 2604.04 16.6549 2604.04 31.5802C2604.04 46.2238 2593 57.7698 2578.19 57.7698ZM2565.75 49.1807H2571.48V34.1147H2578.05C2583.08 34.1147 2585.03 36.0859 2585.03 41.1549C2585.03 43.4077 2585.17 45.0974 2585.45 46.083C2585.59 47.2094 2586.01 48.1951 2586.43 49.1807H2593.28V48.3359C2592.58 47.6319 2592.02 46.6462 2591.88 45.6606C2591.6 44.675 2591.46 43.1261 2591.46 41.1549C2591.46 36.2267 2589.37 33.2698 2585.17 32.2842C2589.78 30.8762 2592.3 27.3561 2592.3 22.5687C2592.3 20.175 2591.32 18.2038 2589.37 16.3733C2587.27 14.6837 2584.61 13.8388 2581.26 13.8388H2565.75V49.1807ZM2571.48 29.1865V18.767H2580.42C2582.24 18.767 2583.64 19.1894 2584.75 20.0342C2585.73 20.8791 2586.29 22.1463 2586.29 23.9768C2586.29 25.8072 2585.73 27.0744 2584.75 27.9193C2583.64 28.7641 2582.24 29.1865 2580.42 29.1865H2571.48Z"
          fill={color}
        />
        <path
          d="M2806.48 19.4983H2809.38V6.25833H2814.91V3.59766H2800.89V6.25833H2806.48V19.4983ZM2816.6 19.4983H2819.5V5.62484L2823.9 19.4983H2827.86L2832.26 5.62484V19.4983H2835.21V3.59766H2829.93L2825.91 16.8377L2821.89 3.59766H2816.6V19.4983Z"
          fill={color}
        />
      </g>
    </g>
  </svg>
);

TickerText.defaultProps = {
  className: ``,
  color: `#FDFBF8`
};

TickerText.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string
};

export default TickerText;
